import React from "react";
import "./Video.css";

export default function Video({ videoSrcUrl, videoTitle }) {
  return (
    <div className="video-container">
      <iframe 
        src={videoSrcUrl}
        title={videoTitle}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        frameBorder="0"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowFullScreen
      />
    </div>
  );
}