// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-reviews-dn-d-edition-switch-mdx": () => import("./../../../src/pages/reviews/DnDEditionSwitch.mdx" /* webpackChunkName: "component---src-pages-reviews-dn-d-edition-switch-mdx" */),
  "component---src-pages-reviews-dragon-quest-1-mdx": () => import("./../../../src/pages/reviews/DragonQuest1.mdx" /* webpackChunkName: "component---src-pages-reviews-dragon-quest-1-mdx" */),
  "component---src-pages-reviews-dragon-quest-4-mdx": () => import("./../../../src/pages/reviews/DragonQuest4.mdx" /* webpackChunkName: "component---src-pages-reviews-dragon-quest-4-mdx" */),
  "component---src-pages-reviews-final-fantasy-16-mdx": () => import("./../../../src/pages/reviews/FinalFantasy16.mdx" /* webpackChunkName: "component---src-pages-reviews-final-fantasy-16-mdx" */),
  "component---src-pages-reviews-final-fantasy-16-wants-mdx": () => import("./../../../src/pages/reviews/FinalFantasy16Wants.mdx" /* webpackChunkName: "component---src-pages-reviews-final-fantasy-16-wants-mdx" */),
  "component---src-pages-reviews-final-fantasy-ranking-mdx": () => import("./../../../src/pages/reviews/FinalFantasyRanking.mdx" /* webpackChunkName: "component---src-pages-reviews-final-fantasy-ranking-mdx" */),
  "component---src-pages-reviews-horizon-vs-god-of-war-mdx": () => import("./../../../src/pages/reviews/HorizonVsGodOfWar.mdx" /* webpackChunkName: "component---src-pages-reviews-horizon-vs-god-of-war-mdx" */),
  "component---src-pages-reviews-js": () => import("./../../../src/pages/reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */),
  "component---src-pages-reviews-loop-hero-mdx": () => import("./../../../src/pages/reviews/LoopHero.mdx" /* webpackChunkName: "component---src-pages-reviews-loop-hero-mdx" */),
  "component---src-pages-reviews-mass-effect-le-mdx": () => import("./../../../src/pages/reviews/MassEffectLE.mdx" /* webpackChunkName: "component---src-pages-reviews-mass-effect-le-mdx" */),
  "component---src-pages-reviews-metroid-dread-mdx": () => import("./../../../src/pages/reviews/MetroidDread.mdx" /* webpackChunkName: "component---src-pages-reviews-metroid-dread-mdx" */),
  "component---src-pages-reviews-monster-hunter-rise-mdx": () => import("./../../../src/pages/reviews/MonsterHunterRise.mdx" /* webpackChunkName: "component---src-pages-reviews-monster-hunter-rise-mdx" */),
  "component---src-pages-reviews-monster-hunter-rise-month-mdx": () => import("./../../../src/pages/reviews/MonsterHunterRiseMonth.mdx" /* webpackChunkName: "component---src-pages-reviews-monster-hunter-rise-month-mdx" */),
  "component---src-pages-reviews-monster-hunter-rise-release-mdx": () => import("./../../../src/pages/reviews/MonsterHunterRiseRelease.mdx" /* webpackChunkName: "component---src-pages-reviews-monster-hunter-rise-release-mdx" */),
  "component---src-pages-reviews-outriders-mdx": () => import("./../../../src/pages/reviews/Outriders.mdx" /* webpackChunkName: "component---src-pages-reviews-outriders-mdx" */),
  "component---src-pages-reviews-ragnarok-mdx": () => import("./../../../src/pages/reviews/Ragnarok.mdx" /* webpackChunkName: "component---src-pages-reviews-ragnarok-mdx" */),
  "component---src-pages-reviews-returnal-mdx": () => import("./../../../src/pages/reviews/Returnal.mdx" /* webpackChunkName: "component---src-pages-reviews-returnal-mdx" */),
  "component---src-pages-reviews-triangle-strategy-mdx": () => import("./../../../src/pages/reviews/TriangleStrategy.mdx" /* webpackChunkName: "component---src-pages-reviews-triangle-strategy-mdx" */),
  "component---src-pages-reviews-xbc-3-mdx": () => import("./../../../src/pages/reviews/Xbc3.mdx" /* webpackChunkName: "component---src-pages-reviews-xbc-3-mdx" */),
  "component---src-pages-reviews-ys-8-mdx": () => import("./../../../src/pages/reviews/Ys8.mdx" /* webpackChunkName: "component---src-pages-reviews-ys-8-mdx" */)
}

